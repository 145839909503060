<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-row>
    <v-col cols="12" md="6" />
    <v-col cols="12" md="6" />
    <div v-for="(doctor, index) in services" :key="index" />
  </v-row>
</template>

<script>

  import * as services from '@/modules/services/api/services'
  import * as forms from '@/modules/forms/api/forms'

  export default {
    name: 'EstablishmentView',
    props: {
      id: String
    },
    data() {
      return{
        loading: false,
        services: [],
        doctors: [],
        forms: []
      }
    },
    computed: {
      doctorItems() {
        return this.doctors ? this.doctors.map(doctor => {
          return {
            text: 'Dr. ' + doctor.firstName + ' ' + doctor.lastName,
            value: doctor['@id']
          }
        }) : []
      }
    },
    created() {
      this.loadServices()
      this.loadForms()
    },
    methods: {
      loadServices() {
        this.loading = true
        try {
          services.list({
            params: {
              establishment: this.id,
              page: this.page,
              itemsPerPage: this.itemsPerPage
            }
          }).then((response) => {
            this.services = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      },
      loadForms() {
        this.loading = true
        try {
          forms.list({
            params: {
              establishment: this.id,
              page: this.page,
              itemsPerPage: this.itemsPerPage
            }
          }).then((response) => {
            this.forms = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
